import React from 'react'
import paymentCard from "../../asset/image/paymentCard.png";
import stripe from "../../asset/image/stripe.png";
import paypal from "../../asset/image/paypal.png";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


function BusinessPayment() {
  return (
    <>
      <div className="business-payment">
          <h2>Payment Details</h2>

          <div className="business-payment-wrap">
             <div className="pay-card">
                <img src={paymentCard} alt="" />
                <Button variant="text" fullWidth sx={{mt:2}}> + Add New Card </Button>
             </div>
            <div className="pay-other-line">
                <span>Or Pay With</span>

                <div className="other-method">
                    <div className="method-item">
                        <img src={paypal} alt=""/>
                    </div>
                    <div className="method-item">
                        <img src={stripe} alt="" /> 
                    </div>
                </div>
            </div>

            <div className="pay-content">
                <div className="total-amount">
                    <h6> Total Amount </h6>
                    <h5>$ 10</h5>
                </div>
                <Link to='/payment-success'>
                   <Button variant="contained" fullWidth sx={{mt:5}}>Pay Now</Button>
                </Link>
            </div>
          </div>
      </div>
    </>
  )
}

export default BusinessPayment