import React, { useState, useEffect } from "react";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { Button } from "@mui/material";
import { baseUrl, userMicrositesUrl } from "../../api/Api";

function ChooseCommuinity() {
  const [commuinity, setCommuinity] = useState(null);
  const token = localStorage.getItem("token");
  const [userMicrosites, setUserMicrosites] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleChange = (event, newValue) => {
    setCommuinity(newValue);
  };

  const getAllUserMicrosites = () => {
    let config = {
      method: "get",
      url: `${userMicrositesUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        const storeBusiness = [];
        if (response?.data?.data && response.data.data.length > 0) { 
          response.data.data.forEach((element) => {
            if (element?.microsites !== null) {
              storeBusiness.push(element);
            }
          });
        }
        setUserMicrosites(storeBusiness);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getAllUserMicrosites();
  }, []);

  const handleNextClick = () => {
    if (commuinity) {
      // Navigate to the /business page with the selected business ID as a query parameter
      navigate(`/business?business_uuid=${commuinity.microsites.uuid}`);
    } else {
      alert("Please select a business or community before proceeding.");
    }
  };

  return (
    <>
      <div className="choose-commuinity">
        <h2>Business / Community Name</h2>

        <div className="choose-commuinity-content">
          {userMicrosites !== null && userMicrosites.length > 0 && (
            <>
              <p>
                Type your business name to create a new business or to import business from iCircles microsites.
              </p>
              <FormControl variant="filled" fullWidth sx={{ mt: 5, mb: 3 }}>
                <InputLabel id="commuinity-select-filled-label">
                  Select a business or a community
                </InputLabel>
                <Autocomplete
                  id="commuinity-autocomplete"
                  value={commuinity}
                  onChange={handleChange}
                  options={userMicrosites.filter(
                    (data) => data.user_type === "admin"
                  )}
                  getOptionLabel={(option) => option.microsites?.name || ""}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      key={option.microsite_id}
                      sx={{
                        padding: "10px 20px",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "#f0f0f0", // Light hover effect
                        },
                      }}
                    >
                      <img
                        src={`${baseUrl}/${option.microsites?.entity_logo}`}
                        alt={option.microsites?.name}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "15px",
                          borderRadius: "50%",
                        }}
                      />
                      <span>{option.microsites?.name}</span>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled" 
                      sx={{
                        "& .MuiInputBase-root": { 
                          backgroundColor: "#f9f9f9",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <div className="item_center">
                <Button
                  variant="contained"
                  onClick={handleNextClick}
                  sx={{
                    mt: 2,
                    padding: "10px 20px",
                    fontSize: "16px",
                    borderRadius: "25px",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          <br /> <br />
          Have no business or community on iCircles?{" "}
          <Link to="/business-type"> Create New </Link>
        </div>
      </div>
    </>
  );
}

export default ChooseCommuinity;
